import React, { useEffect, useRef, useState, useContext } from 'react'

import styled from 'styled-components'
import { useDrop } from 'react-dnd'
import toast from 'react-hot-toast'
import { FiPlus } from 'react-icons/fi'

import DraggableItem from './DraggableItem'
import ClickableItem from './ClickableItem'
import KeywordSearcher from './KeywordSearcher'

import { DnDInputContext } from '../context/DnDInputContext'

const Dropzone = ({
  values,
  noClick,
  className = '',
  errorMessage = '',
  afterValueChanged,
}) => {
  const [enableSearcher, setEnableSearcher] = useState(false)

  const btnRef = useRef(null)

  const { options, setOptions, setKeywordItems } = useContext(DnDInputContext)

  const handleDrop = (item, monitor) => {
    const didDrop = monitor.didDrop()

    const movedOptions = options.filter((option) => option.status === 'moved')

    if (didDrop || movedOptions.length >= 3) {
      toast.error('Professional Headline should not be more than 3')

      return
    }

    const updatedOptions = options.map((option) =>
      option.id === item.id ? { ...option, status: 'moved' } : { ...option }
    )

    setOptions(updatedOptions)
    setKeywordItems(updatedOptions)

    afterValueChanged && afterValueChanged(updatedOptions)
  }

  const handleCollect = (monitor) => ({
    isOver: !!monitor.isOver(),
  })

  const handleDelete = (id) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, status: 'current' } : { ...option }
    )
    setOptions(updatedOptions)
    afterValueChanged && afterValueChanged(updatedOptions)
  }

  const handleExpandButtonClick = () => {
    setEnableSearcher(!enableSearcher)
    setKeywordItems(options)
  }

  useEffect(() => {
    setOptions(values)
  }, [])

  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: handleDrop,
    collect: handleCollect,
  })

  return (
    <div className="inputs">
      <label htmlFor="firstName">
        Professional Headline <span style={{ color: 'red' }}>*</span>
      </label>
      <div className="moreInfo">
        <p>Choose up to 3 keywords to describe yourself professionally</p>
        {!noClick && (
          <ExpandButton
            ref={btnRef}
            onClick={handleExpandButtonClick}
            className="keywordBtn"
          >
            Professional Keywords
            <span>
              <FiPlus className="icon" />
            </span>
          </ExpandButton>
        )}
      </div>
      <div
        className="inputWrapper flex dropzones"
        ref={drop}
        style={{
          background: isOver ? '#f5f5f5' : '#fff',
          border: isOver ? '1px solid #ccc' : '',
          flexWrap: 'wrap',
          borderColor: className === 'has-error' ? 'red' : '#ccc',
        }}
      >
        {options
          .filter((item) => item.status === 'moved')
          .map((e, index) => (
            <>
              <DraggableItem
                key={index}
                index={index}
                id={e.id}
                handleDelete={handleDelete}
                item={e}
              />
              <ClickableItem
                noClose={false}
                key={e.item.id}
                item={e}
                id={e.item.id}
                handleDelete={handleDelete}
                afterValueChanged={afterValueChanged}
              />
            </>
          ))}
      </div>

      {className === 'has-error' && (
        <sub style={{ color: 'red', margin: 0 }}>{errorMessage}</sub>
      )}

      {enableSearcher && (
        <KeywordSearcher
          setCheck={handleExpandButtonClick}
          afterValueChanged={afterValueChanged}
        />
      )}
    </div>
  )
}

const ExpandButton = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 0.7rem;
  font-weight: 500;
  color: #858aa0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &.remove {
    color: red;
  }

  &.mt {
    margin-top: 1rem;
  }

  &:hover {
    color: #31374f;
  }

  span {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: #ffe2e9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    &.mr {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    .icon {
      font-size: 0.7rem;
      color: #fd2055;
    }
  }
`

export default Dropzone
