import React, { useContext, useEffect, useState, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import DatePicker from 'react-datepicker'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import Joi from 'joi'
import styled from 'styled-components'

import StyledWrapper from './StyledWrapper'
import Modal from '../Modal'
import ToggleBtn from '../ToggleBtn'
import RichTextbox from '../RichTextbox'
import ExperienceDetails from '../ExperienceDetails'

import { ModalContext } from '../../context/ModalContext'

import { setExperiences } from '../../redux/feature/applicantSlice'

import apiClient from '../../helpers/apiClient'

import { getFromLocalStorage } from '../../utils/util'

import { industries } from '../../constants/industry'

const EmploymentHistoryUpdate = ({ saveToDatabase }) => {
  const dispatch = useDispatch()

  const experiences = useSelector((state) => state.applicant.experiences)

  const { handleModalToggle } = useContext(ModalContext)

  const [show, setShow] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const [id, setId] = useState(null)
  const [experienceFrom, setExperienceFrom] = useState(new Date())
  const [experienceTo, setExperienceTo] = useState(new Date())
  const [experienceCurrentStatus, setExperienceCurrentStatus] =
    useState('No longer employed')
  const [jobTitle, setJobTitle] = useState('')
  const [jobDescriptionHtml, setJobDescriptionHtml] = useState('')
  const [industry, setIndustry] = useState('')
  const [workType, setWorkType] = useState('Permanent Work From Home')
  const [companyName, setCompanyName] = useState('')
  const [companyLocation, setCompanyLocation] = useState('')
  const [toggle, setToggle] = useState(false)

  const data = {
    jobTitle,
    companyName,
    companyLocation,
    industry,
    workType,
    experienceCurrentStatus,
    jobDescriptionHtml,
    experienceFrom,
    experienceTo,
    id: new Date(),
  }

  const userId = useMemo(() => getFromLocalStorage('user_id'), [])
  const validationSchema = useMemo(() => {
    return Joi.object({
      jobTitle: Joi.string().label('Job Title').required(),
      companyName: Joi.string().label('Company').required(),
      companyLocation: Joi.string().label('Company Location').required(),
      industry: Joi.string().label('Industry').required(),
      workType: Joi.string().label('Work Type').required(),
      experienceCurrentStatus: Joi.string().label('Current Status').required(),
      jobDescriptionHtml: Joi.string()
        .label('Job Description')
        .required()
        .custom((value, helpers) => {
          // Check if the value is empty or contains only an empty paragraph
          if (value.trim() === '<p><br></p>') {
            return helpers.error('any.invalid', {
              label: 'Job Description',
            })
          }
          return value
        })
        .messages({
          'any.required': '"Job Description" is required',
          'any.invalid': '"Job Description" is not allowed to be empty',
        }),
      experienceFrom: Joi.date()
        .label('Date From')
        .less('now')
        .less(new Date(experienceTo))
        .message('Date From must be lesser than Date To and the current date')
        .required(),
      experienceTo: Joi.date()
        .label('Date To')
        .less('now')
        .greater(new Date(experienceFrom))
        .message('Date To must be greater than Date From')
        .required(),
      id: Joi.date().not().required(),
    })
  }, [experienceTo, experienceFrom])

  const clearForm = () => {
    setJobTitle('')
    setCompanyName('')
    setCompanyLocation('')
    setIndustry('')
    setWorkType('Permanent Work From Home')
    setExperienceCurrentStatus('No longer employed')
    setJobDescriptionHtml('')
    setExperienceTo(new Date())
    setExperienceFrom(new Date())
  }

  const handleSaveEmploymentHistory = () => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')
    const meta = JSON.parse(applicantCVData.meta)

    if (experiences.length === 0 && !toggle) {
      toast.error(
        `Please add an employment history or select the 'I have no employment history' option."`
      )
    } else {
      if (applicantCVData) {
        if (saveToDatabase) {
          const newMeta = {
            ...meta,
            experienceState: experiences,
          }

          const reqBody = {
            meta: JSON.stringify(newMeta),
          }
          apiClient
            .put(`/applicant/${userId}/update`, reqBody)
            .then((resp) => {
              localStorage.setItem(
                'applicant-cv-data',
                JSON.stringify({
                  ...applicantCVData,
                  meta: resp.data.data.meta,
                })
              )
              toast.success(resp.data.message)
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message)
            })
        } else {
          const updatedMeta = {
            ...meta,
            experienceState: experiences,
          }

          applicantCVData.meta = JSON.stringify(updatedMeta)
          localStorage.setItem(
            'applicant-cv-data',
            JSON.stringify(applicantCVData)
          )
        }
      }
    }
    handleClose()
    handleModalToggle()
  }

  const handleEdit = (id) => {
    const newData = experiences.find((item) => item.id === id)

    setShow(true)
    setId(newData.id)
    setJobTitle(newData.jobTitle)
    setCompanyName(newData.companyName)
    setCompanyLocation(newData.companyLocation)
    setIndustry(newData.industry)
    setWorkType(newData.workType)
    setExperienceCurrentStatus(newData.experienceCurrentStatus)
    setJobDescriptionHtml(newData.jobDescriptionHtml)
    setExperienceTo(moment(newData.experienceTo).toDate())
    setExperienceFrom(moment(newData.experienceFrom).toDate())
  }

  const handleDelete = (id) => {
    const filteredItems = experiences.filter((item) => item.id !== id)
    dispatch(setExperiences(filteredItems))
  }

  const handleAddEmploymentHistory = () => {
    if (!show) {
      setShow(true)
      clearForm()
    } else {
      const result = validationSchema.validate(data, { abortEarly: false })

      if (!result.error) {
        let updatedExperiences = []

        if (id) {
          updatedExperiences = experiences.map((e) => (e.id === id ? data : e))
        } else {
          updatedExperiences = [...experiences, data]
        }

        dispatch(setExperiences(updatedExperiences))

        setShow(false)
        setId(null)
        setFieldErrors({})
      } else {
        const validationErrors = result.error.details.reduce((acc, curr) => {
          acc[curr.context.key] = curr.message.replace(/"/g, '')
          return acc
        }, {})

        setFieldErrors(validationErrors)
        toast.error('Please correct the validation errors before proceeding.')
      }
    }
  }

  const handleClose = () => {
    setShow(false)
    clearForm()
  }

  useEffect(() => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')

    if (applicantCVData) {
      const meta = JSON.parse(applicantCVData.meta)

      dispatch(setExperiences(meta.experienceState))
    }
  }, [])

  return (
    <Modal
      title="Employment History"
      btnTitle="Save"
      btnFunc={handleSaveEmploymentHistory}
      closeBtnFunc={handleClose}
      showPrimaryBtn={!show}
    >
      <StyledWrapper>
        {experiences?.map(
          (
            {
              jobTitle,
              companyName,
              id,
              companyLocation,
              experienceFrom,
              experienceTo,
              jobDescription,
              experienceCurrentStatus,
            },
            index
          ) => (
            <ExperienceDetails
              key={index}
              jobTitle={jobTitle}
              companyName={companyName}
              experienceCurrentStatus={experienceCurrentStatus}
              companyLocation={companyLocation}
              from={experienceFrom}
              to={experienceTo}
              jobDescription={jobDescription}
              jobDescriptionHtml={jobDescriptionHtml}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              id={id}
            />
          )
        )}

        {experiences?.length === 0 && !show && (
          <div className="toggleBtnWrapper">
            <ToggleBtn toggle={toggle} setToggle={setToggle} />
            <p className="small-subtitle">I have no employment history</p>
          </div>
        )}

        {show && (
          <>
            <div className="inputs">
              <label htmlFor="jobTitle">
                Job Title <span className="required">*</span>
              </label>

              <input
                className={`input ${'jobTitle' in fieldErrors && 'has-error'}`}
                disabled={toggle}
                id="jobTitle"
                type="text"
                placeholder="Job Title"
                onChange={(e) => setJobTitle(e.target.value)}
                value={jobTitle}
              />
              {'jobTitle' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.jobTitle}</sub>
              )}
            </div>

            <div className="inputs">
              <label htmlFor="companyName">
                Company Name <span className="required">*</span>
              </label>

              <input
                className={`input ${
                  'companyName' in fieldErrors && 'has-error'
                }`}
                disabled={toggle}
                id="companyName"
                type="text"
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName}
              />
              {'companyName' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.companyName}</sub>
              )}
            </div>

            <div className="inputs">
              <label htmlFor="companyLocation">
                Company Location <span className="required">*</span>
              </label>

              <input
                className={`input ${
                  'companyLocation' in fieldErrors && 'has-error'
                }`}
                disabled={toggle}
                id="company-location"
                type="text"
                placeholder="Company Location"
                onChange={(e) => setCompanyLocation(e.target.value)}
                value={companyLocation}
              />
              {'companyLocation' in fieldErrors && (
                <sub style={{ color: 'red' }}>
                  {fieldErrors.companyLocation}
                </sub>
              )}
            </div>

            <div className="inputs">
              <label htmlFor="industry">
                Industry <span className="required">*</span>
              </label>

              <select
                style={{
                  borderColor: 'industry' in fieldErrors && 'red',
                }}
                disabled={toggle}
                className="select"
                name=""
                id="industry"
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
              >
                <option>Choose Option</option>

                {industries.map((industry) => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
              {'industry' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.industry}</sub>
              )}
            </div>

            <div className="inputs">
              <label htmlFor="firstName">Job Description</label>
              <div className="moreInfo">
                <p>2-3 sentences that describe your relevant work experience</p>
              </div>

              <RichTextbox
                className={'jobDescriptionHtml' in fieldErrors && 'has-error'}
                content={jobDescriptionHtml}
                setContent={setJobDescriptionHtml}
                isReadOnly={toggle}
              />
            </div>

            <div className="inputs rad">
              <label htmlFor="currentStatus">Work Type</label>

              <div className="radioBtns">
                <div className="radio">
                  <input
                    disabled={toggle}
                    className="radio-input"
                    type="radio"
                    name="workType"
                    id="fromHome"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Permanent Work From Home"
                    checked={workType === 'Permanent Work From Home'}
                  />
                  <label htmlFor="fromHome" className="small-subTitle">
                    Permanent Work From Home
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="workType"
                    id="tempFromHome"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Temporary Work From Home"
                    checked={workType === 'Temporary Work From Home'}
                  />
                  <label htmlFor="tempFromHome" className="small-subTitle">
                    Temporary Work From Home
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="workType"
                    id="office"
                    onChange={(e) => setWorkType(e.target.value)}
                    value="Office"
                    checked={workType === 'Office'}
                  />
                  <label htmlFor="office" className="small-subTitle">
                    Office
                  </label>
                </div>
              </div>
              {'workType' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.workType}</sub>
              )}
            </div>

            <div className="inputs rad">
              <label htmlFor="currentStatus">Current Status</label>

              <div className="radioBtns">
                <div className="radio">
                  <input
                    disabled={toggle}
                    className="radio-input"
                    type="radio"
                    name="notEmployed"
                    id="currentStatus"
                    onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                    value="No longer employed"
                    checked={experienceCurrentStatus === 'No longer employed'}
                  />
                  <label htmlFor="fromHome" className="small-subTitle">
                    No longer employed
                  </label>
                </div>
                <div className="radio">
                  <input
                    className="radio-input"
                    disabled={toggle}
                    type="radio"
                    name="employed"
                    id="currentStatus"
                    onChange={(e) => setExperienceCurrentStatus(e.target.value)}
                    value="Currently employed"
                    checked={experienceCurrentStatus === 'Currently employed'}
                  />
                  <label htmlFor="tempFromHome" className="small-subTitle">
                    Currently employed
                  </label>
                </div>
              </div>
              {'currentStatus' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.currentStatus}</sub>
              )}

              <div className="data-grid">
                <div className="inputs">
                  <label htmlFor="">Date From</label>
                  <StyledDatePickerWrapper>
                    <DatePicker
                      className={'experienceFrom' in fieldErrors && 'has-error'}
                      disabled={toggle}
                      selected={experienceFrom}
                      onChange={(date) => setExperienceFrom(date)}
                      showMonthYearPicker
                      dateFormat="MMMM yyyy"
                      placeholderText="MM/YYYY"
                    />
                  </StyledDatePickerWrapper>
                  {'experienceFrom' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceFrom}
                    </sub>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="">Date To</label>
                  <StyledDatePickerWrapper>
                    <DatePicker
                      className={'experienceTo' in fieldErrors && 'has-error'}
                      disabled={
                        toggle ||
                        experienceCurrentStatus === 'Currently employed'
                      }
                      selected={experienceTo}
                      onChange={(date) => setExperienceTo(date)}
                      showMonthYearPicker
                      placeholderText="MM/YYYY"
                      dateFormat="MMMM yyyy"
                    />
                  </StyledDatePickerWrapper>
                  {'experienceTo' in fieldErrors && (
                    <sub style={{ color: 'red' }}>
                      {fieldErrors.experienceTo}
                    </sub>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="addContainer mt">
          {!toggle && (
            <Button onClick={handleAddEmploymentHistory}>
              {!show ? 'Add employment history' : 'Update employment history'}
            </Button>
          )}

          {show && (
            <CloseFormButton onClick={handleClose}>Close Form</CloseFormButton>
          )}
        </div>
        <Toaster position="top-right" reverseOrder={false} />
      </StyledWrapper>
    </Modal>
  )
}

const StyledDatePickerWrapper = styled.div`
  .has-error {
    color: red;
    border-color: red !important;
  }
`

const Button = styled.button`
  margin-top: 0.5rem;
  border: 1px solid #ff7b9b;
  color: #ff5880;
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 123, 155);
    border: 1px solid rgb(255, 123, 155);
    color: rgb(255, 255, 255);
  }
`

const CloseFormButton = styled.button`
  font-size: 0.75rem;
  font-weight: 400;
  color: rgb(133, 138, 160);
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
`

export default EmploymentHistoryUpdate
