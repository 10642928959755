import React, { useContext, useEffect, useState, useMemo } from 'react'

import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Joi from 'joi'

import 'react-datepicker/dist/react-datepicker.css'

import Modal from '../Modal'
import ProfileDetails from '../ProfileDetails'
import ToggleBtn from '../ToggleBtn'
import StyledWrapper from './StyledWrapper'

import { ModalContext } from '../../context/ModalContext'

import { setEducationalBackground } from '../../redux/feature/applicantSlice'

import apiClient from '../../helpers/apiClient'

import { getFromLocalStorage } from '../../utils/util'

import { levels } from '../../constants/levelOfStudy'

const EducationalBackgroundUpdate = ({ saveToDatabase = false }) => {
  const dispatch = useDispatch()

  const educationalBackground = useSelector(
    (state) => state.applicant.educationalBackground
  )

  const [id, setId] = useState(null)
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [educationLevel, setEducationLevel] = useState('')
  const [fieldOfStudy, setFieldOfStudy] = useState('')
  const [school, setSchool] = useState('')
  const [currentStatus, setCurrentStatus] = useState('graduated')
  const [schoolLocation, setSchoolLocation] = useState('')
  const [eduToggle, setEduToggle] = useState('')
  const [show, setShow] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const { handleModalToggle } = useContext(ModalContext)

  const userId = useMemo(() => getFromLocalStorage('user_id'), [])
  const validationSchema = useMemo(() => {
    if (currentStatus === 'graduated') {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .label('Date From')
          .less('now')
          .less(new Date(to))
          .message('Date From must be lesser than Date To and the current date')
          .required(),
        to: Joi.date()
          .label('Date To')
          .less('now')
          .greater(new Date(from))
          .message('Date To must be greater than Date From')
          .required(),
        id: Joi.date().not().required(),
      })
    } else {
      return Joi.object({
        educationLevel: Joi.string().label('Level of Eucation').required(),
        fieldOfStudy: Joi.string().label('Field of Study').required(),
        school: Joi.string().label('School').required(),
        schoolLocation: Joi.string().label('School Location'),
        currentStatus: Joi.string().label('Status').required(),
        from: Joi.date()
          .less('now')
          .message('Date From must not be from the future')
          .required(),
        to: Joi.date().not().required(),
        id: Joi.date().not().required(),
      })
    }
  }, [currentStatus, to, from])

  const data = {
    educationLevel,
    school,
    schoolLocation,
    fieldOfStudy,
    from,
    to,
    currentStatus,
    id: new Date(),
  }

  const clearForm = () => {
    setEducationLevel('')
    setFieldOfStudy('')
    setSchool('')
    setSchoolLocation('')
    setCurrentStatus('graduated')
    setTo(new Date())
    setFrom(new Date())
  }

  const handleSaveEduBackground = () => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')
    const meta = JSON.parse(applicantCVData.meta)
    if (educationalBackground.length === 0 && !eduToggle) {
      toast.error(
        `Please add an educational background or select the 'I have no educational background' option."`
      )
    } else {
      if (applicantCVData) {
        if (saveToDatabase) {
          const newMeta = {
            ...meta,
            educationalData: educationalBackground,
          }

          const reqBody = {
            meta: JSON.stringify(newMeta),
          }
          apiClient
            .put(`/applicant/${userId}/update`, reqBody)
            .then((resp) => {
              localStorage.setItem(
                'applicant-cv-data',
                JSON.stringify({
                  ...applicantCVData,
                  meta: resp.data.data.meta,
                })
              )
              toast.success(resp.data.message)
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message)
            })
        } else {
          const updatedMeta = {
            ...meta,
            educationalData: educationalBackground,
          }

          applicantCVData.meta = JSON.stringify(updatedMeta)
          localStorage.setItem(
            'applicant-cv-data',
            JSON.stringify(applicantCVData)
          )
        }
      }
      handleClose()
      handleModalToggle()
    }
  }

  const handleDelete = (id) => {
    const filteredItems = educationalBackground.filter((item) => item.id !== id)
    dispatch(setEducationalBackground(filteredItems))
  }

  const handleEdit = (id) => {
    const newData = educationalBackground.find((item) => item.id === id)

    setShow(true)
    setId(newData.id)
    setEducationLevel(newData.educationLevel)
    setFieldOfStudy(newData.fieldOfStudy)
    setSchool(newData.school)
    setSchoolLocation(newData.schoolLocation)
    setTo(moment(newData.to).toDate())
    setFrom(moment(newData.from).toDate())
  }

  const handleAddEduBackground = () => {
    if (!show) {
      setShow(true)
      clearForm()
    } else {
      const result = validationSchema.validate(data, { abortEarly: false })

      if (!result.error) {
        let updatedEduBackground = []

        if (id) {
          updatedEduBackground = educationalBackground.map((e) =>
            e.id === id ? data : e
          )
        } else {
          updatedEduBackground = [...educationalBackground, data]
        }

        dispatch(setEducationalBackground(updatedEduBackground))

        setShow(false)
        setId(null)
        setFieldErrors({})
      } else {
        const validationErrors = result.error.details.reduce((acc, curr) => {
          acc[curr.context.key] = curr.message.replace(/"/g, '')
          return acc
        }, {})

        setFieldErrors(validationErrors)
        toast.error('Please correct the validation errors before proceeding.')
      }
    }
  }

  const handleClose = () => {
    setShow(false)
    clearForm()
  }

  useEffect(() => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')

    if (applicantCVData) {
      const meta = JSON.parse(applicantCVData.meta)

      dispatch(setEducationalBackground(meta.educationalData))
    }
  }, [])

  return (
    <Modal
      title="Educational Background"
      btnTitle="Save"
      btnFunc={handleSaveEduBackground}
      closeBtnFunc={handleClose}
      showPrimaryBtn={!show}
    >
      <StyledWrapper>
        {educationalBackground?.map(
          (
            {
              educationLevel,
              currentStatus,
              school,
              id,
              schoolLocation,
              from,
              to,
            },
            index
          ) => (
            <ProfileDetails
              key={index}
              educationLevel={educationLevel}
              currentStatus={currentStatus}
              school={school}
              schoolLocation={schoolLocation}
              from={from}
              to={to}
              handleDelete={handleDelete}
              id={id}
              handleEdit={handleEdit}
            />
          )
        )}

        {/* toggle button */}
        {educationalBackground?.length === 0 && !show && (
          <div className="toggleBtnWrapper">
            <ToggleBtn toggle={eduToggle} setToggle={setEduToggle} />
            <p className="small-subtitle">I have no educational background</p>
          </div>
        )}

        <div className="inputs">
          {show && (
            <>
              {/* Level of Education */}
              <div className="inputs">
                <label htmlFor="level">
                  Level of Education <span className="required">*</span>
                </label>

                <select
                  onChange={(e) => setEducationLevel(e.target.value)}
                  disabled={eduToggle}
                  style={{
                    borderColor: 'educationLevel' in fieldErrors && 'red',
                  }}
                  className="select"
                  value={educationLevel}
                  name=""
                  id="level"
                >
                  <option>Choose Option</option>
                  {levels.map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
                {'educationLevel' in fieldErrors && (
                  <sub style={{ color: 'red' }}>
                    {fieldErrors.educationLevel}
                  </sub>
                )}
              </div>

              {/* Field of Study */}
              <div className="inputs">
                <label htmlFor="fieldOfStudy">
                  Field of Study <span className="required">*</span>
                </label>

                <input
                  className={`input ${
                    'fieldOfStudy' in fieldErrors && 'has-error'
                  }`}
                  disabled={eduToggle}
                  id="fieldOfStudy"
                  type="text"
                  placeholder="e.g. Agriculture, Law, Chemistry"
                  onChange={(e) => setFieldOfStudy(e.target.value)}
                  value={fieldOfStudy}
                />
                {'fieldOfStudy' in fieldErrors && (
                  <sub style={{ color: 'red' }}>{fieldErrors.fieldOfStudy}</sub>
                )}
              </div>

              {/* School */}
              <div className="inputs">
                <label htmlFor="school">
                  School <span className="required">*</span>
                </label>

                <input
                  className={`input ${'school' in fieldErrors && 'has-error'}`}
                  disabled={eduToggle}
                  id="school"
                  type="text"
                  placeholder="School"
                  onChange={(e) => setSchool(e.target.value)}
                  value={school}
                />
                {'school' in fieldErrors && (
                  <sub style={{ color: 'red' }}>{fieldErrors.school}</sub>
                )}
              </div>

              {/* School Location */}
              <div className="inputs">
                <label htmlFor="schoolLocation">
                  School Location <span className="required">*</span>
                </label>

                <input
                  className={`input ${
                    'schoolLocation' in fieldErrors && 'has-error'
                  }`}
                  disabled={eduToggle}
                  id="school-location"
                  type="text"
                  placeholder="School Location"
                  onChange={(e) => setSchoolLocation(e.target.value)}
                  value={
                    schoolLocation.label ? schoolLocation.label : schoolLocation
                  }
                />
                {'schoolLocation' in fieldErrors && (
                  <sub style={{ color: 'red' }}>
                    {fieldErrors.schoolLocation}
                  </sub>
                )}
              </div>

              {/* Current Status */}
              <div className="inputs">
                <label htmlFor="currentStatus">Current Status</label>

                <div className="radioBtns">
                  <div className="radio">
                    <input
                      disabled={eduToggle}
                      className="radio-input"
                      type="radio"
                      name="status"
                      id="graduated"
                      value="graduated"
                      checked={currentStatus === 'graduated'}
                      onChange={(e) => setCurrentStatus(e.target.value)}
                    />
                    <label htmlFor="graduated" className="small-subTitle">
                      Graduated
                    </label>
                  </div>
                  <div className="radio">
                    <input
                      disabled={eduToggle}
                      className="radio-input"
                      type="radio"
                      name="status"
                      id="currently"
                      value="currently enrolled"
                      checked={currentStatus === 'currently enrolled'}
                      onChange={(e) => setCurrentStatus(e.target.value)}
                    />
                    <label htmlFor="currently" className="small-subTitle">
                      Currently Enrolled
                    </label>
                  </div>
                </div>
              </div>

              {/* Data Grid */}
              <div className="data-grid">
                <div className="inputs">
                  <label htmlFor="">
                    Date From <span className="required">*</span>
                  </label>
                  <DatePicker
                    className={'from' in fieldErrors && 'has-error'}
                    disabled={eduToggle}
                    selected={from}
                    onChange={(date) => setFrom(date)}
                    showMonthYearPicker
                    dateFormat="MMMM yyyy"
                    placeholderText="MM/YYYY"
                  />
                  {'from' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.from}</sub>
                  )}
                </div>
                <div className="inputs">
                  <label htmlFor="">
                    Date To <span className="required">*</span>
                  </label>
                  <DatePicker
                    className={'to' in fieldErrors && 'has-error'}
                    disabled={
                      eduToggle || currentStatus === 'currently enrolled'
                    }
                    selected={
                      currentStatus === 'currently enrolled' ? undefined : to
                    }
                    onChange={(date) => setTo(date)}
                    showMonthYearPicker
                    placeholderText="MM/YYYY"
                    dateFormat="MMMM yyyy"
                  />
                  {'to' in fieldErrors && (
                    <sub style={{ color: 'red' }}>{fieldErrors.to}</sub>
                  )}
                </div>
              </div>
            </>
          )}
          <Toaster position="top-right" reverseOrder={false} />

          {!eduToggle && (
            <div className="addContainer mt">
              <Button onClick={handleAddEduBackground}>
                {!show
                  ? 'Add educational background'
                  : 'Update educational background'}
              </Button>

              {show && (
                <CloseFormButton onClick={handleClose}>
                  Close Form
                </CloseFormButton>
              )}
            </div>
          )}
        </div>
      </StyledWrapper>
    </Modal>
  )
}

const Button = styled.button`
  margin-top: 0.5rem;
  border: 1px solid #ff7b9b;
  color: #ff5880;
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 123, 155);
    border: 1px solid rgb(255, 123, 155);
    color: rgb(255, 255, 255);
  }
`

const CloseFormButton = styled.button`
  font-size: 0.75rem;
  font-weight: 400;
  color: rgb(133, 138, 160);
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
`

export default EducationalBackgroundUpdate
