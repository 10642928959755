import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Dropzone from './components/Dropzone'

import { DnDInputProvider } from './context/DnDInputContext'

const DnDinput = ({
  values,
  noClick,
  dropzoneClassName = '',
  errorMessage = '',
  afterValueChanged = null,
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DnDInputProvider>
        <Dropzone
          values={values}
          noClick={noClick}
          className={dropzoneClassName}
          errorMessage={errorMessage}
          afterValueChanged={afterValueChanged}
        />
      </DnDInputProvider>
    </DndProvider>
  )
}

export default DnDinput
