import { React, createContext, useState } from 'react'

export const DnDInputContext = createContext(null)
export const DnDInputProvider = ({ children }) => {
  const [options, setOptions] = useState([])
  const [keywordItems, setKeywordItems] = useState([])

  return (
    <DnDInputContext.Provider
      value={{
        options,
        setOptions,
        keywordItems,
        setKeywordItems,
      }}
    >
      {children}
    </DnDInputContext.Provider>
  )
}
