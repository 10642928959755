import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

import { StyledPersonalInfo } from './PersonalInfo'
import DownloadCVModal from '../welcome/components/DownloadCVModal'
import Slider from '../../components/Slider'
import SkillsUpdate from '../../components/onboardingUpdate/SkillsUpdate'

import ProfileDetailsUpdate from '../../components/onboardingUpdate/ProfileDetailsUpdate'

import EducationalBackgroundUpdate from '../../components/applicantDetails/EducationalBackgroundUpdate'
import EmploymentHistoryUpdate from '../../components/applicantDetails/EmploymentHistoryUpdate'
import CharacterReferenceUpdate from '../../components/applicantDetails/CharacterReferenceUpdate'
import CharacterReferences from '../../components/applicantDetails/CharacterReferences'
import EducationalBackground from '../../components/applicantDetails/EducationalBackground'
import EmploymentHistory from '../../components/applicantDetails/EmploymentHistory'

import { ModalContext } from '../../context/ModalContext'
import { PagesContext } from '../../context/FormPagesContext'

import {
  setEducationalBackground,
  setExperiences,
  setCharacterReferences,
} from '../../redux/feature/applicantSlice'

import { getFromLocalStorage, getFullName } from '../../utils/util'

import user from '../../assets/images/user.svg'
import pencil from '../../assets/icons/pencil.svg'

import { baseUrl } from '../../constants/constant'

const PreviewCV = () => {
  const history = useHistory()

  const dispatch = useDispatch()

  const { experiences, educationalBackground, characterReferences } =
    useSelector((state) => state.applicant)

  const { handleModalToggle, setCloseModal } = useContext(ModalContext)
  const { file, userData, setPage } = useContext(PagesContext)

  const [modal, setModal] = useState('')
  const [loading, setLoading] = useState(false)

  let applicantCVData = localStorage.getItem('applicant-cv-data')
  applicantCVData = JSON.parse(applicantCVData)
  const applicantCVMeta = JSON.parse(applicantCVData.meta)
  let applicantData = localStorage.getItem('applicant-data')
  applicantData = JSON.parse(applicantData)

  const professionalHeadlines = JSON.parse(
    applicantCVData.professional_headline
  )

  const clearLocalStorage = () => {
    localStorage.removeItem('imgLink')
    localStorage.removeItem('cvImgLink')
    localStorage.removeItem('file')
  }

  const data = {
    first_name: applicantCVData?.first_name,
    middle_name: applicantCVData?.middle_name || '',
    last_name: applicantCVData?.last_name,
    city: applicantData?.city,
    professional_headline: applicantCVData?.professional_headline,
    professional_summary: JSON.stringify(applicantCVData?.professional_summary),
    phone_number: applicantData?.phone_number,
    country: applicantData?.country,
    meta: JSON.stringify({
      educationalData: applicantCVMeta?.educationalData || [],
      experienceState: applicantCVMeta?.experienceState || [],
      fullSkills: applicantCVMeta.fullSkills,
      ZIPCode: applicantCVMeta.ZIPCode,
      streetAddress: applicantCVMeta.streetAddress,
    }),
    characterReferences: JSON.stringify(applicantCVData?.character_reference),
  }

  useEffect(() => {
    dispatch(setExperiences(applicantCVMeta.experienceState))
    dispatch(setEducationalBackground(applicantCVMeta.educationalData))

    let cachedCharacterReferences

    if (typeof applicantCVData?.character_reference === 'string') {
      cachedCharacterReferences = JSON.parse(
        applicantCVData?.character_reference
      )
    } else {
      cachedCharacterReferences = applicantCVData?.character_reference
    }

    dispatch(setCharacterReferences(cachedCharacterReferences))
  }, [])

  const handleSubmit = () => {
    const formData = new FormData()

    formData.append('first_name', data.first_name)
    formData.append('middle_name', data.middle_name)
    formData.append('last_name', data.last_name)
    formData.append('city', data.city)
    formData.append('professional_headline', data.professional_headline)
    formData.append('professional_summary', data.professional_summary)
    formData.append('phone_number', data.phone_number)
    formData.append('country', data.country)
    formData.append('meta', data.meta)
    formData.append('character_reference', data.characterReferences)
    formData.append('cv_image_url', getFromLocalStorage('profileImgLink'))

    if (file) {
      formData.append('cvImage', file)
    }

    setLoading(true)
    axios
      .post(`${baseUrl}/user/${userData.id}/create-cv`, formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': `multipart/form-data;`,
        },
      })
      .then(() => {
        toast.success('CV updated successfully')

        setLoading(false)
        clearLocalStorage()

        history.push('/remote-setup')
      })
      .catch((err) => {
        setLoading(false)
        toast.error(
          err?.response?.data?.message || 'Some fields are not filled.'
        )
      })
  }

  const viewModal = (name) => {
    handleModalToggle()
    setModal(name)
  }

  return (
    <StyledPersonalInfo>
      <h2>Review your CV</h2>
      <p className="small-subtitle">
        Preview your CV and make sure it&apos;s all correct
      </p>

      <StyledDetails>
        <img
          src={pencil}
          alt="pencil icon"
          className="edit"
          onClick={() => viewModal('profile')}
        />

        {modal === 'profile' && <ProfileDetailsUpdate />}
        {modal === 'skill' && <SkillsUpdate />}
        {modal === 'education' && <EducationalBackgroundUpdate />}
        {modal === 'employment' && <EmploymentHistoryUpdate />}
        {modal === 'characterRef' && <CharacterReferenceUpdate />}

        <div className="flex">
          <img
            src={getFromLocalStorage('profileImgLink') || user}
            className="user-image"
          />
          <div className="user-details">
            <h4>
              {getFullName(
                data?.first_name,
                data?.middle_name,
                data?.last_name
              )}
            </h4>
            <p>
              {data?.city}, {data?.country}
            </p>
            <p>{applicantCVData?.email}</p>
            <p>{data?.phone_number}</p>
          </div>
        </div>

        <p
          className="more-details"
          dangerouslySetInnerHTML={{ __html: data?.professional_summary }}
          style={{ wordBreak: 'break-all' }}
        />
      </StyledDetails>

      {/* Educational Background */}
      <StyledDetails>
        <EducationalBackground
          educationalData={educationalBackground}
          editIcon={pencil}
          handleEditModal={() => viewModal('education')}
        />
      </StyledDetails>

      {/* Employment History */}
      <StyledDetails>
        <EmploymentHistory
          experiences={experiences}
          handleEditModal={() => viewModal('employment')}
          editIcon={pencil}
        />
      </StyledDetails>

      {/* Skills and Headlines */}
      <StyledDetails>
        <img
          src={pencil}
          alt="pencil icon"
          className="edit"
          onClick={() => viewModal('skill')}
        />

        <h6>Professional Headlines</h6>
        <div className="headlines">
          {professionalHeadlines?.map(({ id, item }) => (
            <div key={id} className="headline__badge">
              {item}
            </div>
          ))}
        </div>

        <div className="two-grid">
          <div className="section">
            <h6>Technical Skills</h6>

            {applicantCVMeta?.fullSkills[0]?.map((skill) => {
              return (
                <div key={skill} className="skill">
                  <div className="small-title">{skill.skill}</div>
                  <Slider value={skill.experience} />
                </div>
              )
            })}
          </div>

          <div className="section">
            <h6>Personal Skills</h6>

            <div className="newSkill">
              {applicantCVMeta?.fullSkills[1]?.map(({ label }) => (
                <div key={label} className="skillCard">
                  {label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Toaster position="top-right" reverseOrder={false} />
      </StyledDetails>

      {/* Character references */}
      <StyledDetails>
        <CharacterReferences
          characterReferences={characterReferences}
          editIcon={pencil}
          handleEditModal={() => viewModal('characterRef')}
        />

        <DownloadCVModal />

        <div className="buttonContainer">
          <button className="btn" onClick={() => setPage(1)}>
            Go Back
          </button>
          <button className="btn filled" onClick={() => setCloseModal(true)}>
            Preview CV
          </button>
          <button className="btn filled" onClick={handleSubmit}>
            {loading ? 'Loading...' : 'Save CV'}
          </button>
        </div>
      </StyledDetails>
    </StyledPersonalInfo>
  )
}

export default PreviewCV

const StyledDetails = styled.div`
  width: 100%;
  border-top: 1px solid #eaeaf8;
  padding: 2rem 0;
  padding-bottom: 0;
  margin-top: 1.75rem;
  position: relative;

  .edit {
    position: absolute;
    top: 1.875rem;
    right: 0;
    cursor: pointer;
    width: 0.875rem;
    height: auto;
  }

  .flex {
    display: flex;
    align-items: flex-start;

    .user-image {
      width: 125px;
      height: 125px;
      object-fit: cover;
      margin-right: 1.5rem;
      border-radius: 50%;
    }

    .user-details {
      flex: 1;
    }
  }

  .two-grid {
    display: grid;
    grid-template-columns: 35% 50%;
    grid-gap: 3rem;
  }

  .headlines {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    .headline__badge {
      padding: 0.5rem 0.75rem;
      border: 0.5px solid rgb(49, 55, 79);
      text-align: center;
      font-size: 0.75rem;
      font-weight: 300;
      border-radius: 100px;
      margin-right: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }

  .newSkill {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;

    .skillCard {
      padding: 1rem 1.2rem;
      border: 1px solid #dfdfe9;
      color: #0e1324;
      text-align: center;
      font-size: 0.7rem;
      font-weight: 300;
      border-radius: 100px;
      margin-right: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }

  .skill {
    margin-top: 1rem;
  }

  .lined-section {
    padding-left: 1rem;
    border-left: 1px solid #ced4ee;
    position: relative;
    padding-bottom: 1.5rem;

    &.first {
      margin-top: 1.25rem;
    }

    &:last-child {
      border-left: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: -0.5px;
      transform: translate(-50%, -50%);
      width: 0.438rem;
      height: 0.438rem;
      border: 1px solid #ced4ee;
      border-radius: 50%;
      background: #fff;
    }
  }

  .small-title {
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.03em;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 300;
    letter-spacing: 0.03em;
    line-height: 1.5;
    color: #31374f;
  }

  h6 {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 1.5;
    color: #0e1324;
  }

  p {
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 0.6rem;
    color: #0e1324;
    letter-spacing: 0.03em;

    &.more-details {
      line-height: 1.6;
      margin-top: 1.5rem;

      &.new {
        margin-top: 0.7rem;

        &::first-letter {
          margin-left: 1.25rem;
        }
      }
    }
  }
`
