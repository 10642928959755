import React from 'react'

import styled from 'styled-components'
import moment from 'moment'
import { sanitize } from 'dompurify'

const Experiences = ({ experiences, editIcon, handleEditModal }) => {
  return (
    <>
      <Header>
        <Title>Employment History</Title>
        <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
      </Header>
      {experiences.length > 0 ? (
        experiences.map((job, index) => (
          <Article key={job.id || index}>
            <div className="left__section">
              <p className="item__title">
                {moment(job.experienceFrom).format('MMMM YYYY')} -{' '}
                {job.experienceCurrentStatus !== 'Currently employed'
                  ? moment(job.experienceTo).format('MMMM YYYY')
                  : 'Present'}
              </p>
              <p className="company">
                {job.companyName}
                &nbsp; - &nbsp;
                {job.companyLocation}
              </p>
            </div>
            <div className="right__section">
              <p className="item__title">{job.jobTitle}</p>
              <p
                className="job__description"
                dangerouslySetInnerHTML={{
                  __html: sanitize(job?.jobDescriptionHtml),
                }}
              ></p>
            </div>
          </Article>
        ))
      ) : (
        <p>No prior employment history</p>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const EditIcon = styled.img`
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
`

const Article = styled.article`
  display: flex;
  padding: 10px 0;

  .left__section {
    width: 40%;
  }

  .right__section {
    width: 60%;
  }

  .item__title {
    font-size: 14px;
    color: #31374f;
    font-weight: 600;
  }

  .company {
    font-size: 12px;
    color: #7d849e;
    font-weight: 500;
  }

  .job__description {
    font-size: 12px;
    color: #31374f;
    font-weight: 400;
    line-height: 22.5px;
    text-align: justify;
  }
`

export default Experiences
