/* eslint-disable */
import React, { useEffect, useContext } from 'react'

import axios from 'axios'

import RangeSlider from './RangeSlider'
import SkillSelectableInput from './SkillSelectableInput'

import { TechnicalSkillInputContext } from '../context/TechnicalSkillInputContext'

import { baseUrl } from '../../../constants/constant'

import { getFromLocalStorage } from '../../../utils/util'

const TechnicalSkillInput = ({
  skills = [],
  newOption,
  setNewOption,
  setSkills,
  handleDelete,
}) => {
  const applicantCVData = getFromLocalStorage('applicant-cv-data')
  const professionalHeadlines = JSON.parse(
    applicantCVData.professional_headline
  )
  const ids = JSON.stringify(professionalHeadlines.map((e) => e.id))

  const { technicalSkillOptions, setTechnicalSkillOptions } = useContext(
    TechnicalSkillInputContext
  )

  useEffect(() => {
    axios
      .get(`${baseUrl}/technical-skills?professionalHeadline_id=${ids}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const fetchedSkills = res.data.resp

        const skillIds = new Set(skills.map((skill) => skill.id))
        // const updatedOptions = fetchedRecords.filter(
        //   (item) => !skillIds.has(item.id)
        // )

        const updatedOptions = fetchedSkills.map((skill) => {
          let checked = false
          if (skillIds.has(skill.id)) {
            checked = true
          }
          return {
            checked,
            id: skill.id,
            experience: skill.experience,
            label: skill.skills,
          }
        })

        // console.log(fetchedSkills)

        // console.log(skills)
        // console.log(fetchedSkills[0])

        setTechnicalSkillOptions(updatedOptions)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <div className="inputs">
        <label htmlFor="skill">
          Technical Skills (New version) <span className="required">*</span>
        </label>
        <div className="moreInfo" style={{ marginBottom: '1rem' }}>
          <p>Choose up to 5 Technical Skills</p>
        </div>

        <SkillSelectableInput
          newOption={newOption}
          setNewOption={setNewOption}
        />

        {/* {skills?.map(({ skill, experience, id }) => (
          <RangeSlider
            key={id}
            skill={skill}
            experience={experience}
            id={id}
            setSkills={setSkills}
            skills={skills}
            handleDelete={handleDelete}
          />
        ))} */}
      </div>
    </>
  )
}

export default TechnicalSkillInput
