import React, { useContext } from 'react'

import styled from 'styled-components'
import toast from 'react-hot-toast'

import { IoCloseOutline } from 'react-icons/io5'

import { DnDInputContext } from '../context/DnDInputContext'

const ClickableItem = ({
  item,
  noClose,
  handleDelete,
  afterValueChanged = null,
}) => {
  const { options, setOptions, setKeywordItems } = useContext(DnDInputContext)

  const handleClick = (id) => {
    const movedOptions = options.filter((option) => option.status === 'moved')

    if (movedOptions.length >= 3) {
      toast.error('Professional Headline should not be more than 3')

      return
    }

    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, status: 'moved' } : { ...option }
    )

    setOptions(updatedOptions)
    setKeywordItems(updatedOptions)
    afterValueChanged ?? afterValueChanged(updatedOptions)
  }

  return (
    <StyledClickedElement onClick={() => handleClick(item.id)}>
      <div className="item">{item.item}</div>

      {noClose === false && (
        <IoCloseOutline
          onClick={(e) => {
            e.stopPropagation()
            handleDelete(item.id)
          }}
          className="icon close"
        />
      )}
    </StyledClickedElement>
  )
}

const StyledClickedElement = styled.div`
  display: none;

  @media (max-width: 768px) {
    position: relative;
    display: block;
    cursor: pointer;

    .item {
      padding: 0.5rem;
      background: #f2f3f8;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.75rem;
      margin-bottom: 0.75rem;
      transition: 0.3s ease-in all;
      font-size: 0.75rem;
      padding-right: 2rem;
    }

    .icon {
      font-size: 1rem;
      color: #676e92;
      position: absolute;
      top: 35%;
      right: 1rem;
      transform: translateY(-50%);

      &.close {
        cursor: pointer;

        &:hover {
          color: red;
        }
      }
    }
  }
`

export default ClickableItem
