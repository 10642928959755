import React from 'react'

import styled from 'styled-components'

const CharacterReferences = ({
  characterReferences = [],
  editIcon,
  handleEditModal,
}) => {
  return (
    <>
      <Header>
        <Title>Character References</Title>
        <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
      </Header>
      {characterReferences !== null && (
        <Article>
          <ul className="character__references">
            {characterReferences?.map((cr) => (
              <li key={cr.id}>
                <div className="character__reference">
                  <span className="name">{cr.crName}</span>
                  <span className="email">{cr.crEmail}</span>
                  <span className="contact__number">{cr.crPhone}</span>
                </div>
              </li>
            ))}
          </ul>
        </Article>
      )}
    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const EditIcon = styled.img`
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
  font-size: inherit;
`

const Article = styled.article`
  width: 50%;
  padding-right: 20px;
  font-family: inherit;

  .character__references {
    list-style-type: none;
    margin: 0.5rem 0;
  }

  .character__reference {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-top: 1rem;

    .name {
      font-size: 14px;
      color: #31374f;
      font-weight: 400;
      display: block;
    }

    .email,
    .contact__number {
      font-size: 12px;
      color: #7d849e;
      font-weight: 500;
      display: block;
    }
  }
`

export default CharacterReferences
