import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: '',
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalName: (state, action) => {
      state.name = action.payload
    },
  },
})

export const { setModalName } = modalSlice.actions

export default modalSlice.reducer
