import React, { useEffect, useState } from 'react'

import axios from 'axios'

import DnDinput from './DnDInput'

import { getFromLocalStorage } from '../utils/util'

import { baseUrl } from '../constants/constant'

const ProfessionalHeadlines = ({ values = [] }) => {
  const [headlines, setHeadlines] = useState([])
  const [loading, setLoading] = useState(false)

  const getProfessionalHeadlines = () => {
    setLoading(true)

    axios
      .get(`${baseUrl}/professional-headlines?withSkills=true`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        const data = res.data.resp.map((item) => ({
          id: item.id,
          item: item.professional_headline,
          status: 'current',
        }))

        const updatedOptions = data.map((item) => {
          const match = values.find((el) => el.id === item.id)

          return match ? { ...item, status: 'moved' } : item
        })

        setHeadlines(updatedOptions)
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false))
  }

  const handleAfterValueChanged = (items) => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')
    const movedItems = items.filter((e) => e.status === 'moved')
    const updatedCVData = {
      ...applicantCVData,
      professional_headline: JSON.stringify(movedItems),
    }

    localStorage.setItem('applicant-cv-data', JSON.stringify(updatedCVData))
  }

  useEffect(() => {
    getProfessionalHeadlines()
  }, [values])

  return loading ? (
    <h1>Loading headlines...</h1>
  ) : (
    <DnDinput values={headlines} afterValueChanged={handleAfterValueChanged} />
  )
}

export default ProfessionalHeadlines
