/* eslint-disable */
import React from 'react'

import TechnicalSkillInput from './components/TechnicalSkillInput'

import { TechnicalSkillInputProvider } from './context/TechnicalSkillInputContext'

const TechnicalSkills = ({
  skills,
  newOption,
  setNewOption,
  setSkills,
  handleDelete,
}) => {
  return (
    <TechnicalSkillInputProvider>
      <TechnicalSkillInput
        skills={skills}
        newOption={newOption}
        setNewOption={setNewOption}
        setSkills={setSkills}
        handleDelete={handleDelete}
      />
    </TechnicalSkillInputProvider>
  )
}

export default TechnicalSkills
