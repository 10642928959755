import React from 'react'

import styled from 'styled-components'

const Wrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

const StyledWrapper = styled.div`
  width: 100%;

  .small-subtitle {
    font-size: 0.75rem;
    font-weight: 300;
    color: #0e1324;
    letter-spacing: 0.03em;
    line-height: 1.5rem;
  }

  .toggleBtnWrapper {
    margin: 2rem 0;
    display: flex;
    align-items: center;
  }

  .data-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;

    .react-datepicker-wrapper {
      display: block;
      margin-top: 1rem;
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;

        input {
          width: 100%;
          height: 2.5rem;
          border-radius: 3px;
          border: 1px solid #aeb3c9;
          padding: 0 1rem;
          outline: none;
        }
      }
    }
  }

  .radioBtns {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .radio {
      display: flex;
      align-items: center;
      margin-right: 2rem;

      .radio-input {
        opacity: 1;
        accent-color: #ff7b9b;
        width: 19px;
        height: 19px;
      }

      label {
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.7rem;
      line-height: 1.7;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &.has-error {
        border-color: red;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.75rem;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3% inherit;
      }
    }

    .inputWrapper {
      width: 100%;
      min-height: 60px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }

    .label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 0.03em;
      margin-bottom: 0.5rem;
    }

    .input {
      height: 2rem;
      width: 100%;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      background: none;
      outline: none;
      padding: 0 0.75rem;
    }
  }

  .addContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button.keywordBtn {
    background: none;
    outline: none;
    border: none;
    font-size: 0.7rem;
    font-weight: 500;
    color: #858aa0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    &.remove {
      color: red;
    }

    &.save-edit {
      margin-left: auto;
      padding-right: 2rem;
      color: red;
    }

    &.mt {
      margin-top: 1rem;
    }

    &:hover {
      color: #31374f;
    }

    span {
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      background: #ffe2e9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      &.mr {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .icon {
        font-size: 0.7rem;
        color: #fd2055;
      }
    }
  }

  .css-14el2xx-placeholder,
  .css-1wa3eu0-placeholder,
  .ck-placeholder {
    color: #858aa0;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .required {
    color: red;
  }
`

export default Wrapper
