import { React, createContext, useState } from 'react'

export const TechnicalSkillInputContext = createContext(null)
export const TechnicalSkillInputProvider = ({ children }) => {
  const [technicalSkillOptions, setTechnicalSkillOptions] = useState([])

  return (
    <TechnicalSkillInputContext.Provider
      value={{
        technicalSkillOptions,
        setTechnicalSkillOptions,
      }}
    >
      {children}
    </TechnicalSkillInputContext.Provider>
  )
}
