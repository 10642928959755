import { configureStore } from '@reduxjs/toolkit'
import { api } from './services/api'

import jobReducer from './feature/jobSlice'
import cvReducer from './feature/cvSlice'
import applicantReducer from './feature/applicantSlice'
import modalReducer from './feature/modalSlice'

export const store = configureStore({
  reducer: {
    jobs: jobReducer,
    cv: cvReducer,
    applicant: applicantReducer,
    modal: modalReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
})
