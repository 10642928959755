/* eslint-disable */

import React, { useContext, useEffect, useState, useMemo } from 'react'

import styled from 'styled-components'
import axios from 'axios'
import { components } from 'react-select'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import SelectableInput from '../../../../../../components/onboardingUpdate/SelectableInput'
import Modal from '../../../../../../components/Modal'
import RangeSlider from '../../../../../../components/RangeSlider'
import CustomCreatableSelect from '../../../../../../components/CustomCreatableSelect'
import TechnicalSkills from '../../../../../../components/TechnicalSkills'

import { PagesContext } from '../../../../../../context/FormPagesContext'
import { ModalContext } from '../../../../../../context/ModalContext'

import { setTechnicalSkills } from '../../../../../../redux/feature/applicantSlice'

import { getFromLocalStorage } from '../../../../../../utils/util'

import { baseUrl } from '../../../../../../constants/constant'

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>Five Skills Completed</div>
      )}
    </components.Menu>
  )
}

const SkillsUpdate = ({ handleHardUpdate }) => {
  const cvData = getFromLocalStorage('applicant-cv-data')
  const meta = cvData.meta !== null ? JSON.parse(cvData.meta) : null
  const currentTechnicalSkills = meta?.fullSkills[0]
  const currentSoftSkills = meta?.fullSkills[1]

  const dispatch = useDispatch()

  const technicalSkills = useSelector(
    (state) => state.applicant.technicalSkills
  )

  const [newOption, setNewOption] = useState([])

  const { handleModalToggle } = useContext(ModalContext)
  const {
    skills,
    setSkills,
    softSkills,
    setSoftSkills,
    softSkillOption,
    setSoftSkillOption,
    setFullSkills,
    userDetails,
    option,
    setOption,
    professionalHeadlinesId,
    setProfessionalHeadlinesId,
    setItems,
  } = useContext(PagesContext)

  const selectedHeadlines = useMemo(() => {
    let res
    if (cvData?.professional_headline) {
      res = JSON.parse(cvData?.professional_headline)
    } else {
      res = userDetails.professionalHeadlines
    }
    return res
  }, [cvData.professional_headline, userDetails.professionalHeadlines])

  const getTechnicalSkills = (ids) => {
    axios
      .get(`${baseUrl}/technical-skills?professionalHeadline_id=${ids}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        let techSkills = res.data.resp

        const skillsToFilter = [
          ...new Set(currentTechnicalSkills.map((item) => item.skill)),
        ]

        techSkills = techSkills.map((item) => {
          if (skillsToFilter.includes(item.skills)) {
            return {
              ...item,
              checked: true,
            }
          } else {
            return {
              ...item,
              checked: false,
            }
          }
        })

        const newTechSkills = techSkills.map((skill) => ({
          value: skill.skills,
          label: skill.skills,
          skill: skill.skills,
          professional_headline_id: skill.professional_headline_id,
          experience: 1,
          id: skill.id,
          checked: skill?.checked || false,
        }))
        setOption(newTechSkills)
        setNewOption(newTechSkills)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getSoftSkills = () => {
    axios
      .get(`${baseUrl}/skills`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        const newSoftSkills = res.data.data.softSkills.map((skill) => {
          if (skill !== '') {
            return {
              value: skill,
              label: skill,
            }
          } else {
            return 'skill'
          }
        })
        setSoftSkillOption(newSoftSkills)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDelete = (id) => {
    setSkills(skills.filter((skill) => skill.id !== id))
    const removedOptions = option.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: false,
        }
      } else {
        return {
          ...item,
        }
      }
    })
    setOption(removedOptions)
    setNewOption(removedOptions)
  }

  const handleUpdate = () => {
    const cvData = JSON.parse(localStorage.getItem('applicant-cv-data'))
    const oldMeta = cvData && cvData.meta ? JSON.parse(cvData.meta) : {}

    if (handleHardUpdate) {
      handleHardUpdate()
    } else {
      if (skills.length > 0 && softSkills.length > 0) {
        setFullSkills([skills, softSkills])
        const meta = JSON.stringify({
          ...oldMeta,
          fullSkills: [skills, softSkills],
        })
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: meta,
          })
        )
        handleModalToggle()
      } else {
        toast.error('Please select at least one Technical skill and Soft skill')
      }
    }
  }

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5

  useEffect(() => {
    getSoftSkills()

    setItems(selectedHeadlines)
    setProfessionalHeadlinesId(selectedHeadlines.map((hl) => hl.id))
    setSkills(currentTechnicalSkills)
    setSoftSkills(currentSoftSkills)
  }, [])

  // console.log(currentTechnicalSkills)

  useEffect(() => {
    getTechnicalSkills(JSON.stringify(professionalHeadlinesId))
  }, [professionalHeadlinesId])

  return (
    <Modal title="Skills" btnTitle="Update" btnFunc={handleUpdate}>
      <StyledSkillUpdate>
        <div className="inputs">
          <label htmlFor="skill">
            Technical Skills <span className="required">*</span>
          </label>
          <div className="moreInfo" style={{ marginBottom: '1rem' }}>
            <p>Choose up to 5 Technical Skills</p>
          </div>

          <SelectableInput newOption={newOption} setNewOption={setNewOption} />
        </div>
        {skills?.map(({ skill, experience, id }) => (
          <RangeSlider
            key={id}
            skill={skill}
            experience={experience}
            id={id}
            setSkills={setSkills}
            skills={skills}
            handleDelete={handleDelete}
          />
        ))}

        <TechnicalSkills
          skills={[
            {
              skill: 'Account Management',
              experience: 1,
              id: 80,
            },
            {
              skill: 'Adobe Illustrator',
              experience: 1,
              id: 757,
            },
            {
              skill: 'Adobe InDesign',
              experience: 1,
              id: 758,
            },
            {
              skill: 'Buyer-Responsive selling',
              experience: 1,
              id: 90,
            },
            {
              skill: 'Testing/Debugging',
              experience: 1,
              id: 499,
            },
          ]}
          newOption={newOption}
          setNewOption={setNewOption}
          setSkills={setSkills}
          handleDelete={handleDelete}
        />

        {/* Soft skills */}
        <div className="inputs mt">
          <label htmlFor="skill">
            Soft Skills <span className="required">*</span>
          </label>
          <div className="moreInfo" style={{ marginBottom: '1rem' }}>
            <p>Choose up to 5 Soft Skills</p>
          </div>
          <CustomCreatableSelect
            components={{ Menu }}
            isMulti
            isValidNewOption={isValidNewOption}
            value={softSkills}
            options={softSkillOption}
            onChange={(value) => setSoftSkills(value)}
            placeholder="Select up to 5 Soft Skills"
          />
        </div>
      </StyledSkillUpdate>

      <Toaster position="top-right" reverseOrder={false} />
    </Modal>
  )
}

const StyledSkillUpdate = styled.div`
  width: 100%;
  max-height: 90vh;

  .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.7rem;
      line-height: 1.7;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &.has-error {
        border-color: red;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.75rem;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3% inherit;
      }
    }

    .inputWrapper {
      width: 100%;
      min-height: 30px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }

    .label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 0.03em;
      margin-bottom: 0.5rem;
    }

    .input {
      height: 2rem;
      width: 100%;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      background: none;
      outline: none;
      padding: 0 0.75rem;
    }
  }
`

export default SkillsUpdate
