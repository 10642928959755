import React, { useState, useMemo, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Joi from 'joi'
import toast from 'react-hot-toast'

import Modal from '../../../components/Modal'
import { PhoneNumberInput } from '../../../components/Input'
import ProfessionalHeadlines from '../../../components/ProfessionalHeadlines'
import Select from '../../../components/MiniComponents/Select'

import apiClient from '../../../helpers/apiClient'

import { getFromLocalStorage } from '../../../utils/util'

import countries from 'all-countries-and-cities-json'

import {
  setFirstName,
  setMiddleName,
  setLastName,
  setEmail,
  setContactNumber,
  setCountry,
  setCity,
} from '../../../redux/feature/applicantSlice'

const ApplicantDetailsModal = () => {
  const applicantCVData = getFromLocalStorage('applicant-cv-data')
  const applicantData = getFromLocalStorage('applicant-data')
  const userId = useMemo(() => getFromLocalStorage('user_id'), [])

  const [loading, setLoading] = useState(false)
  const [fieldErrors, setFieldErrors] = useState({})

  const validationSchema = useMemo(() => {
    return Joi.object({
      firstName: Joi.string().label('First Name').required(),
      middleName: Joi.string().label('Middle Name').allow(''),
      lastName: Joi.string().label('Last Name').required(),
      email: Joi.string()
        .label('Email')
        .email({ tlds: { allow: false } })
        .required(),
      contactNumber: Joi.string().label('Contact Number').required(),
      country: Joi.string().label('Country').required(),
      city: Joi.string().label('City').required(),
    })
  }, [])

  const {
    firstName,
    middleName,
    lastName,
    email,
    contactNumber,
    country,
    city,
  } = useSelector((state) => state.applicant)

  const dispatch = useDispatch()

  const countryList = useMemo(() => Object.keys(countries), [])
  const cityList = useMemo(() => countries[country] || [], [country])

  const selectedHeadlines = useMemo(
    () => JSON.parse(applicantCVData?.professional_headline),
    [applicantCVData]
  )

  const handleCountryChange = (value) => {
    dispatch(setCountry(value))
    dispatch(setCity(cityList[0]))
  }

  const handleSaveApplicantDetails = () => {
    try {
      setLoading(true)
      const formData = {
        firstName,
        middleName,
        lastName,
        email,
        contactNumber,
        country,
        city,
      }

      const result = validationSchema.validate(formData, { abortEarly: false })

      if (!result.error) {
        const updatedApplicantCV = getFromLocalStorage('applicant-cv-data')
        const updatedHeadlines = updatedApplicantCV.professional_headline

        const reqBody = {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email,
          phone_number: contactNumber,
          city,
          country,
          professional_headline: updatedHeadlines,
        }
        apiClient
          .put(`/applicant/${userId}/update`, reqBody)
          .then((resp) => {
            const result = resp.data.data

            const updatedApplicantCVData = {
              ...applicantCVData,
              first_name: result.first_name,
              middle_name: result.middle_name,
              last_name: result.last_name,
              email: result.email,
              phone_number: result.phone_number,
              professional_headline: result.professional_headline,
            }

            localStorage.setItem(
              'applicant-cv-data',
              JSON.stringify(updatedApplicantCVData)
            )

            const updatedApplicantData = {
              ...applicantData,
              email: result.email,
              phone_number: result.phone_number,
              city: result.city,
              country: result.country,
            }

            localStorage.setItem(
              'applicant-data',
              JSON.stringify(updatedApplicantData)
            )

            toast.success(resp.data.message)
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message)
          })
      } else {
        const validationErrors = result.error.details.reduce((acc, curr) => {
          acc[curr.context.key] = curr.message.replace(/"/g, '')
          return acc
        }, {})

        setFieldErrors(validationErrors)
        toast.error('Please correct the validation errors before proceeding.')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (applicantCVData) {
      dispatch(setFirstName(applicantCVData.first_name))
      dispatch(setMiddleName(applicantCVData.middle_name))
      dispatch(setLastName(applicantCVData.last_name))
    }

    if (applicantData) {
      dispatch(setEmail(applicantData.email))
      dispatch(setContactNumber(applicantData.phone_number))

      const applicantCountry =
        applicantData?.country || Object.keys(countries)[0]
      const applicantCity =
        applicantData?.city || countryList[applicantCountry][0]

      dispatch(setCountry(applicantCountry))
      dispatch(setCity(applicantCity))
    }
  }, [])

  return (
    <Modal
      title="Applicant Details"
      btnTitle={
        loading ? 'Updating applicant details' : 'Save Applicant Details'
      }
      btnFunc={handleSaveApplicantDetails}
      containerClass="with__headlines"
    >
      <StyledApplicantDetailsForm>
        <div className="inline">
          <div className="grouped">
            <label htmlFor="firstName">First Name</label>
            <input
              className={`input ${'firstName' in fieldErrors && 'has-error'}`}
              id="firstName"
              type="text"
              placeholder="First Name"
              onChange={(e) => dispatch(setFirstName(e.target.value))}
              value={firstName}
            />
          </div>
          <div className="grouped">
            <label htmlFor="middleName">Middle Name</label>
            <input
              className={`input ${'middleName' in fieldErrors && 'has-error'}`}
              id="middleName"
              type="text"
              placeholder="Middle Name"
              onChange={(e) => dispatch(setMiddleName(e.target.value))}
              value={middleName}
            />
          </div>
          <div className="grouped">
            <label htmlFor="lastName">Last Name</label>
            <input
              className={`input ${'lastName' in fieldErrors && 'has-error'}`}
              id="lastName"
              type="text"
              placeholder="Last Name"
              onChange={(e) => dispatch(setLastName(e.target.value))}
              value={lastName}
            />
          </div>
        </div>

        <div className="inline">
          <div className="grouped">
            <label htmlFor="email">Email Address</label>
            <input
              className={`input ${'email' in fieldErrors && 'has-error'}`}
              id="email"
              type="text"
              placeholder="Email Address"
              onChange={(e) => dispatch(setEmail(e.target.value))}
              value={email}
            />
          </div>
        </div>

        <div className="contact__number__wrapper">
          <PhoneNumberInput
            title="Phone Number"
            country="ph"
            value={contactNumber}
            onChange={(value) => dispatch(setContactNumber(value))}
          />
        </div>

        <div className="inline">
          <div className="grouped">
            <label htmlFor="country">Country</label>
            <Select
              className="country"
              data={countryList}
              value={country}
              placeholder="Select Country"
              handleSelect={handleCountryChange}
            />
          </div>
          <div className="grouped">
            <label htmlFor="city">City</label>
            <Select
              className="city"
              data={cityList}
              value={city}
              placeholder="Select City"
              handleSelect={(value) => dispatch(setCity(value))}
            />
          </div>
        </div>

        <ProfessionalHeadlines values={selectedHeadlines} />
      </StyledApplicantDetailsForm>
    </Modal>
  )
}

export default ApplicantDetailsModal

const StyledApplicantDetailsForm = styled.div`
  padding-top: 1rem;

  input {
    width: 100%;
    height: 2.5rem;
    border-radius: 3px;
    border: 1px solid #aeb3c9;
    padding: 1rem;
    margin: 0.5rem 0;
    outline: none;
  }

  .has-error {
    color: red;
    border-color: red !important;
  }

  .grouped {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .inline {
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .contact__number__wrapper {
    margin-bottom: 1rem;
  }

  .inputs {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    &.mt {
      margin-top: 1.5rem;
    }

    &.rad {
      margin-bottom: 2rem;
    }

    label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 3%;
      margin-bottom: 0.7rem;
      line-height: 1.7;
    }

    .select,
    .input {
      display: block;
      margin-top: 0.7rem;
      width: 100%;
      height: 2.5rem;
      border-radius: 3px;
      border: 1px solid #aeb3c9;
      padding: 0 1rem;
      outline: none;
      color: #858aa0;
      cursor: pointer;

      &.unique {
        border: none;
        margin-top: 0;
        height: 100%;
      }

      &.has-error {
        border-color: red;
      }

      &::placeholder {
        color: #858aa0;
        font-size: 0.75rem;
      }
    }

    .react-tel-input {
      width: 100%;
      margin-top: 0.7rem;
      border-radius: 3px;

      .form-control {
        width: 100%;
        height: 2.5rem;
        border: 1px solid #aeb3c9;
        border-radius: 3px;
      }
    }

    .moreInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 0.7rem;
        font-weight: 300;
        color: #31374f;
        letter-spacing: 3% inherit;
      }
    }

    .inputWrapper {
      width: 100%;
      min-height: 30px;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      padding: 0.875rem;
      margin-top: 0.5rem;

      &.flex {
        display: flex;
        align-items: center;
        padding-bottom: 0.375rem;
      }

      input.new {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
      }
    }

    .label {
      font-size: 0.875rem;
      font-weight: 300;
      color: #0e1324;
      letter-spacing: 0.03em;
      margin-bottom: 0.5rem;
    }

    .input {
      height: 2rem;
      width: 100%;
      border: 1px solid #aeb3c9;
      border-radius: 3px;
      background: none;
      outline: none;
      padding: 0 0.75rem;
    }
  }
`
