import React, { useEffect, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'styled-components'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../assets/icons/pencil.svg'

import SkillsUpdate from './components/SkillsUpdate'
import EmploymentHistoryUpdate from '../../../../../components/applicantDetails/EmploymentHistoryUpdate'
import EducationalBackgroundUpdate from '../../../../../components/applicantDetails/EducationalBackgroundUpdate'
import CharacterReferenceUpdate from '../../../../../components/applicantDetails/CharacterReferenceUpdate'

import EmploymentHistory from '../../../../../components/applicantDetails/EmploymentHistory'
import EducationalBackground from '../../../../../components/applicantDetails/EducationalBackground'
import Skills from './components/Skills'
import CharacterReferences from '../../../../../components/applicantDetails/CharacterReferences'
import WFHSetup from './components/WFHSetup'
import RemoteFitness from './components/RemoteFitness'

import { ModalContext } from '../../../../../context/ModalContext'
import { PagesContext } from '../../../../../context/FormPagesContext'

import { setHasSkillChange } from '../../../../../redux/feature/jobSlice'
import { setModalName } from '../../../../../redux/feature/modalSlice'
import {
  setExperiences,
  setEducationalBackground,
  setCharacterReferences,
} from '../../../../../redux/feature/applicantSlice'

import apiClient from '../../../../../helpers/apiClient'

import { getFromLocalStorage } from '../../../../../utils/util'

const MyCV = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const experiences = useSelector((state) => state.applicant.experiences)
  const educationalBackground = useSelector(
    (state) => state.applicant.educationalBackground
  )
  const characterReferences = useSelector(
    (state) => state.applicant.characterReferences
  )
  const modalName = useSelector((state) => state.modal.name)

  const { skills, softSkills, setSoftSkills, setSkills, setFullSkills, items } =
    useContext(PagesContext)

  const { handleModalToggle, setRemotePage, setWorkEthicsPage } =
    useContext(ModalContext)

  const wfh = useMemo(() => getFromLocalStorage('applicant-wfh'), [])
  const cvData = useMemo(() => getFromLocalStorage('applicant-cv-data'), [])
  const userId = useMemo(() => getFromLocalStorage('user_id'), [])
  const meta = useMemo(
    () => (cvData.meta !== null ? JSON.parse(cvData.meta) : null),
    []
  )

  const computerSpec = useMemo(
    () => (wfh !== null ? JSON.parse(wfh.computer_specification) : wfh),
    []
  )
  const internetSpeedTest = useMemo(
    () => (wfh !== null ? JSON.parse(wfh.internet_speed_test) : wfh),
    []
  )
  const peripherals = useMemo(
    () => (wfh !== null ? JSON.parse(wfh.peripherals) : wfh),
    []
  )
  const remoteFitness = wfh !== null ? JSON.parse(wfh.meta) : wfh

  const professionalHeadlines = useMemo(() =>
    getFromLocalStorage('professionalHeadlinesId')
      ? JSON.parse(cvData.professional_headline).map((hl) => hl.id)
      : []
  )

  const handleEditExperiences = () => {
    handleModalToggle()
    dispatch(setModalName('employment'))
  }
  const handleEditEducationalBackground = () => {
    handleModalToggle()
    dispatch(setModalName('education'))
  }
  const handleEditSkills = () => {
    handleModalToggle()
    dispatch(setModalName('skill'))
  }
  const handleEditCharacterReferences = () => {
    handleModalToggle()
    dispatch(setModalName('characterRef'))
  }
  const handleEditWFHSetup = () => {
    setRemotePage(1)
    history.push('/remote-setup/update')
  }
  const handleEditRemoteFitness = () => {
    setWorkEthicsPage(1)
    history.push('/remote-fitness-level/update')
  }

  const handleUpdateSkills = () => {
    setFullSkills([skills, softSkills])
    const newMeta = JSON.stringify({
      ...meta,
      fullSkills: [skills, softSkills],
    })

    const mappedHeadlines = items?.filter((item) =>
      professionalHeadlines.includes(item.id)
    )

    const reqBody = {
      meta: newMeta,
      professional_headline: JSON.stringify(mappedHeadlines),
    }

    apiClient
      .put(`/applicant/${userId}/update`, reqBody)
      .then((resp) => {
        localStorage.setItem(
          'applicant-cv-data',
          JSON.stringify({
            ...cvData,
            meta: resp.data.data.meta,
            professional_headline: resp.data.data.professional_headline,
          })
        )

        dispatch(setHasSkillChange(true))
        toast.success(resp.data.message)
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message)
      })
  }

  useEffect(() => {
    dispatch(setExperiences(meta.experienceState))
    dispatch(setEducationalBackground(meta.educationalData))

    const cachedCharacterReferences = cvData?.character_reference
      ? JSON.parse(cvData?.character_reference)
      : []
    dispatch(setCharacterReferences(cachedCharacterReferences))
    setSkills(meta.fullSkills[0])
    setSoftSkills(meta.fullSkills[1])
  }, [])

  // FIXME: WFH Setup and Remote Fitness Level not appearing after going to /profile immediately upon user account creation (uploaded cv)

  return (
    <>
      <SectionWrapper>
        <EmploymentHistory
          experiences={experiences}
          editIcon={pencil}
          handleEditModal={handleEditExperiences}
        />
      </SectionWrapper>
      <SectionWrapper>
        <EducationalBackground
          educationalData={educationalBackground}
          editIcon={pencil}
          handleEditModal={handleEditEducationalBackground}
        />
      </SectionWrapper>

      {meta !== null && (
        <SectionWrapper className="flex">
          <Skills
            skills={[skills, softSkills]}
            editIcon={pencil}
            handleEditModal={handleEditSkills}
          />
        </SectionWrapper>
      )}

      {characterReferences && (
        <SectionWrapper>
          <CharacterReferences
            characterReferences={characterReferences}
            editIcon={pencil}
            handleEditModal={handleEditCharacterReferences}
          />
        </SectionWrapper>
      )}

      {wfh !== null && (
        <SectionWrapper>
          <WFHSetup
            internetSpeedTest={internetSpeedTest}
            computerSpec={computerSpec}
            peripherals={peripherals}
            editIcon={pencil}
            handleEditModal={handleEditWFHSetup}
          />
        </SectionWrapper>
      )}

      {remoteFitness !== null && (
        <SectionWrapper>
          <RemoteFitness
            remoteFitness={remoteFitness}
            editIcon={pencil}
            handleEditModal={handleEditRemoteFitness}
          />
        </SectionWrapper>
      )}

      {modalName === 'employment' && <EmploymentHistoryUpdate saveToDatabase />}
      {modalName === 'education' && (
        <EducationalBackgroundUpdate saveToDatabase />
      )}
      {modalName === 'skill' && (
        <SkillsUpdate handleHardUpdate={handleUpdateSkills} />
      )}
      {modalName === 'characterRef' && (
        <CharacterReferenceUpdate saveToDatabase />
      )}
    </>
  )
}

const SectionWrapper = styled.section`
  margin-top: 0.5rem;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaf8;
  position: relative;

  &.flex {
    display: flex;
    padding: 10px 0;
  }
`

export default MyCV
