import React, { useContext, useEffect, useState, useMemo } from 'react'

import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-input-2'

import toast, { Toaster } from 'react-hot-toast'
import Joi from 'joi'

import CharacterRefDetails from '../CharacterRefDetails'
import ScrollToTop from '../ScrollRestoration'
import Modal from '../Modal'
import ToggleBtn from '../ToggleBtn'
import StyledWrapper from './StyledWrapper'

import { ModalContext } from '../../context/ModalContext'

import { setCharacterReferences } from '../../redux/feature/applicantSlice'

import apiClient from '../../helpers/apiClient'

import { getFromLocalStorage } from '../../utils/util'

const CharacterReferenceUpdate = ({ saveToDatabase = false }) => {
  const dispatch = useDispatch()

  const characterReferences = useSelector(
    (state) => state.applicant.characterReferences
  )
  const userId = useMemo(() => getFromLocalStorage('user_id'), [])

  const [show, setShow] = useState(false)

  const [fieldErrors, setFieldErrors] = useState({})

  const [id, setId] = useState(null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [toggle, setToggle] = useState(false)

  const { handleModalToggle } = useContext(ModalContext)

  const validationSchema = useMemo(() => {
    return Joi.object({
      id: Joi.date().not().required(),
      crName: Joi.string().label('Full Name').required(),
      crEmail: Joi.string()
        .label('Email')
        .email({ tlds: { allow: false } })
        .required(),
      crPhone: Joi.string().label('Contact Number').required(),
    })
  }, [])

  const clearForm = () => {
    setName('')
    setEmail('')
    setPhoneNumber('')
  }

  const handleEditCharacterReference = (id) => {
    const newData = characterReferences.find((item) => item.id === id)

    setShow(true)
    setId(newData.id)
    setName(newData.crName)
    setEmail(newData.crEmail)
    setPhoneNumber(newData.crPhone)
  }

  const handleDeleteCharacterReference = (id) => {
    const filteredItems = characterReferences.filter((item) => item.id !== id)
    dispatch(setCharacterReferences(filteredItems))
  }

  const handleSaveCharacterReferences = () => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')

    if (characterReferences.length === 0 && !toggle) {
      toast.error(
        `Please add a character reference or select the 'I have no character references' option."`
      )
    } else {
      if (applicantCVData) {
        if (saveToDatabase) {
          // save to db
          const reqBody = {
            character_reference: JSON.stringify(characterReferences),
          }
          apiClient
            .put(`/applicant/${userId}/update`, reqBody)
            .then((resp) => {
              localStorage.setItem(
                'applicant-cv-data',
                JSON.stringify({
                  ...applicantCVData,
                  character_reference: resp.data.data.character_reference,
                })
              )
              toast.success(resp.data.message)
            })
            .catch((error) => {
              toast.error(error?.response?.data?.message)
            })
        } else {
          applicantCVData.character_reference =
            JSON.stringify(characterReferences)
          localStorage.setItem(
            'applicant-cv-data',
            JSON.stringify(applicantCVData)
          )
        }
      }
    }
    handleClose()
    handleModalToggle()
  }

  const handleAddCharacterReference = () => {
    if (!show) {
      setShow(true)
      clearForm()
    } else {
      const data = {
        crName: name,
        crEmail: email,
        crPhone: phoneNumber,
        id: new Date(),
      }
      const result = validationSchema.validate(data, { abortEarly: false })

      if (!result.error) {
        let updatedCharacterReferences = []

        if (id) {
          updatedCharacterReferences = characterReferences.map((e) =>
            e.id === id ? data : e
          )
        } else {
          updatedCharacterReferences = [...characterReferences, data]
        }

        dispatch(setCharacterReferences(updatedCharacterReferences))

        setShow(false)
        setId(null)
        setFieldErrors({})
      } else {
        const validationErrors = result.error.details.reduce((acc, curr) => {
          acc[curr.context.key] = curr.message.replace(/"/g, '')
          return acc
        }, {})

        setFieldErrors(validationErrors)
        toast.error('Please correct the validation errors before proceeding.')
      }
    }
  }

  const handleClose = () => {
    setShow(false)
    clearForm()
  }

  const handleToggle = () => {
    setToggle(!toggle)
    clearForm()
  }

  useEffect(() => {
    const applicantCVData = getFromLocalStorage('applicant-cv-data')

    if (applicantCVData) {
      const cachedCharacterReferences = JSON.parse(
        applicantCVData.character_reference
      )

      dispatch(setCharacterReferences(cachedCharacterReferences))
    }
  }, [])

  return (
    <Modal
      title="Character Reference"
      btnTitle="Save"
      btnFunc={handleSaveCharacterReferences}
      showPrimaryBtn={!show}
    >
      <StyledWrapper>
        <ScrollToTop />

        {characterReferences?.length > 0 ? (
          characterReferences?.map((c) => (
            <CharacterRefDetails
              key={c.id}
              crName={c.crName}
              crEmail={c.crEmail}
              crPhone={c.crPhone}
              handleDelete={handleDeleteCharacterReference}
              handleEdit={handleEditCharacterReference}
              id={c.id}
            />
          ))
        ) : (
          <div className="toggleBtnWrapper">
            <ToggleBtn toggle={toggle} setToggle={handleToggle} />
            <p className="small-subtitle">I have no character references</p>
          </div>
        )}

        {show && !toggle && (
          <>
            <div className="inputs">
              <label htmlFor="jobTitle">
                Full Name <span className="required">*</span>
              </label>
              <input
                className={`input ${'crName' in fieldErrors && 'has-error'}`}
                id="name"
                type="text"
                placeholder="Full Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              {'crName' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crName}</sub>
              )}
            </div>
            <div className="inputs">
              <label htmlFor="jobTitle">
                Email <span className="required">*</span>
              </label>
              <input
                className={`input ${'crEmail' in fieldErrors && 'has-error'}`}
                id="email"
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {'crEmail' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crEmail}</sub>
              )}
            </div>
            <div className="inputs">
              <label htmlFor="firstName">
                Contact Number<span className="required">*</span>
              </label>
              <PhoneInput
                inputStyle={{ borderColor: 'crPhone' in fieldErrors && 'red' }}
                country={'ph'}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
              />
              {'crPhone' in fieldErrors && (
                <sub style={{ color: 'red' }}>{fieldErrors.crPhone}</sub>
              )}
            </div>
          </>
        )}

        <Toaster position="top-right" reverseOrder={false} />

        {!toggle && (
          <div className="addContainer mt">
            <Button onClick={handleAddCharacterReference}>
              {!show
                ? 'Add another character reference'
                : 'Update character reference'}
            </Button>

            {show && (
              <CloseFormButton onClick={handleClose}>
                Close Form
              </CloseFormButton>
            )}
          </div>
        )}
      </StyledWrapper>
    </Modal>
  )
}

const Button = styled.button`
  margin-top: 0.5rem;
  border: 1px solid #ff7b9b;
  color: #ff5880;
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: rgb(255, 123, 155);
    border: 1px solid rgb(255, 123, 155);
    color: rgb(255, 255, 255);
  }
`

const CloseFormButton = styled.button`
  font-size: 0.75rem;
  font-weight: 400;
  color: rgb(133, 138, 160);
  padding: 0.75rem 1.3rem;
  border-radius: 100px;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
`

export default CharacterReferenceUpdate
