import React, { useEffect } from 'react'

import styled from 'styled-components'

import { IoIosClose } from 'react-icons/io'

const getStatus = (val) => {
  if (val === 1) {
    return 'Beginner'
  } else if (val === 2) {
    return 'Novice'
  } else if (val === 3) {
    return 'Skilled'
  } else if (val === 4) {
    return 'Experienced'
  } else if (val === 5) {
    return 'Expert'
  }
}

const RangeSlider = ({
  skill,
  experience,
  id,
  setSkills,
  skills,
  handleDelete,
  disable,
}) => {
  const [value, setValue] = React.useState(experience)
  const [style, setStyle] = React.useState('Beginner')

  useEffect(() => {
    const newSkill = {
      skill,
      experience: value,
      id,
    }

    const index = skills.findIndex((skill) => skill.id === id)

    const updatedSkills = skills

    updatedSkills.splice(index, 1, newSkill)

    setSkills(updatedSkills)

    setStyle(getStatus(value))
  }, [value])

  return (
    <RangeSliderContainer>
      <div className="details">
        <IoIosClose onClick={() => handleDelete(id, skill)} className="icon" />
        <div className="title">{skill}</div>
      </div>

      <div className="slider">
        <p>{style}</p>
        <input
          onChange={(e) => {
            setValue(parseInt(e.target.value))
          }}
          value={value}
          className={`slider ${style}`}
          type="range"
          min="1"
          max="5"
        />
      </div>
    </RangeSliderContainer>
  )
}

export default RangeSlider

const RangeSliderContainer = styled.div`
  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaf8;

  .details {
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      font-size: 1rem;
      margin-right: 0.5rem;
      color: #acb2cc;
      cursor: pointer;
    }

    .title {
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.03rem;
      color: #31374f;
    }
  }

  .slider {
    width: 100%;
    max-width: 318px;

    p {
      font-size: 0.625rem;
      font-weight: 500;
      color: #acb2cc;
    }

    .slider {
      width: 100%;
      height: 2px;
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: linear-gradient(
        90deg,
        #e4f79c -1.65%,
        #cae463 23.76%,
        #a2dd74 49.71%,
        #7dd585 76.18%,
        #5cca95 100%
      );
      border-radius: 100px;
      transition: all 0.3s ease-in-out;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
        background: #ddf382;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }

      &.Novice {
        &::-webkit-slider-thumb {
          background: #cae463;
        }
      }

      &.Skilled {
        &::-webkit-slider-thumb {
          background: #a2dd74;
        }
      }

      &.Experienced {
        &::-webkit-slider-thumb {
          background: #7dd585;
        }
      }

      &.Expert {
        &::-webkit-slider-thumb {
          background: #5cca95;
          box-shadow: 0px 0px 0px 12px rgba(92, 202, 149, 0.2);
        }
      }
    }
  }
`
