import React from 'react'
import styled from 'styled-components'

import { getExperienceLevel } from '../../../../../../utils/util'

const Skills = ({ skills, editIcon, handleEditModal }) => {
  return (
    <Wrapper>
      {skills !== null && (
        <Article className="pad--20">
          <Title>Technical Skills</Title>
          <ul className="technical__skills">
            {skills[0].map((skill) => (
              <li key={skill.id}>
                <div className="technical__skill">
                  <div className="left__section">
                    <span className="skill__name">{skill.skill}</span>
                    <span className="experience">
                      {getExperienceLevel(parseInt(skill.experience))}
                    </span>
                  </div>
                  <div className="right__section">
                    <div className="progress__container">
                      <div
                        className={`progress__bar skill--${skill.experience}`}
                      ></div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Article>
      )}

      {skills !== null && skills.length > 1 && (
        <Article>
          <Title>Personal Skills</Title>
          <div className="personal__skills">
            {skills[1].map((skill) => (
              <div className="personal__skill" key={skill.value}>
                {skill.label}
              </div>
            ))}
          </div>
        </Article>
      )}
      <EditIcon src={editIcon} alt="icon" onClick={handleEditModal} />
    </Wrapper>
  )
}

const EditIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 0px;
  color: #31374f;
  cursor: pointer;
`

const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: #31374f;
`

const Article = styled.article`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }

  &.pad--20 {
    padding-right: 20px;
  }

  .technical__skills {
    list-style-type: none;
    margin-top: 0.5rem;

    .technical__skill {
      display: flex;
      padding: 5px 0;
    }

    .left__section {
      width: 40%;

      .skill__name {
        display: block;
        font-size: 14px;
        color: #31374f;
        font-weight: 400;
      }

      .experience {
        margin-top: 0.5rem;
        font-size: 12px;
        color: #7d849e;
        font-weight: 500;
      }
    }

    .right__section {
      width: 60%;
      padding: 0 1rem;

      .progress__container {
        width: 100%;
        background-color: #f3f3f3;
        height: 8px;
        border-radius: 10px;
        margin-top: 10px;

        .progress__bar {
          width: 0;
          height: 30px;
          background: linear-gradient(to right, #c2de50, #5cca95);
          text-align: center;
          line-height: 30px;
          color: white;
          height: 8px;
          border-radius: 10px;
          margin-top: 10px;

          &.skill--5 {
            width: 100%;
          }
          &.skill--4 {
            width: 80%;
          }
          &.skill--3 {
            width: 60%;
          }
          &.skill--2 {
            width: 40%;
          }
          &.skill--1 {
            width: 20%;
          }
        }
      }
    }
  }

  .personal__skills {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
      width: 100%;
    }

    .personal__skill {
      padding: 8px 12px;
      border: 1px solid #31374f;
      border-radius: 20px;
      font-size: 14px;
      margin-right: 5px;
      margin-bottom: 10px;
      display: inline;
      color: #31374f;
    }
  }
`

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`

export default Skills
