import React, { useState, useRef, useContext } from 'react'

import styled from 'styled-components'

import OutsideClickHandler from 'react-outside-click-handler'

import { FiSearch } from 'react-icons/fi'
import { RiVipCrownLine } from 'react-icons/ri'

import DraggableItem from './DraggableItem'
import ClickableItem from './ClickableItem'

import { DnDInputContext } from '../context/DnDInputContext'

const KeywordSearcher = ({ setCheck, afterValueChanged = null }) => {
  const { options, keywordItems, setKeywordItems } = useContext(DnDInputContext)

  const [noFilter, setNoFilter] = useState(false)

  const searchRef = useRef()

  const handleSearch = (e) => {
    const value = e.target.value.trim().toLowerCase()

    const filteredItems = options.filter(
      (item) =>
        item.status === 'current' && item.item.toLowerCase().includes(value)
    )

    setKeywordItems(filteredItems)

    if (filteredItems.length === 0) {
      setNoFilter(true)
    }
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setCheck(false)}>
      <StyledKeywordSearcher>
        <div className="heading">
          <FiSearch className="icon" />
          <input
            ref={searchRef}
            className="input2"
            type="text"
            onChange={handleSearch}
            placeholder="Filter keywords e.g. Creative, Marketing Specialist, Consultant"
          />
        </div>

        <div className="subTitle">
          <RiVipCrownLine className="icon crown" />
          <span className="text">
            Popular Keywords - ( Please drag and drop )
          </span>
        </div>

        {noFilter && <div className="noHeadline">Headline not Found</div>}

        <div className="lists">
          {keywordItems
            .filter((item) => item.status === 'current')
            .map((item, index) => (
              <>
                <DraggableItem
                  key={index + 5}
                  item={item}
                  id={item.id}
                  index={index}
                  noClose={true}
                  afterValueChanged={afterValueChanged}
                />
                <ClickableItem
                  noClose={true}
                  key={index}
                  item={item}
                  id={item.item.id}
                  afterValueChanged={afterValueChanged}
                />
              </>
            ))}
        </div>
      </StyledKeywordSearcher>
    </OutsideClickHandler>
  )
}

export default KeywordSearcher

export const StyledKeywordSearcher = styled.div`
  position: absolute;
  top: 20%;
  left: 102%;
  width: 100%;
  max-width: 340px;
  padding: 1.25rem;
  background-color: #fff;
  border: 1px solid #eeeef6;
  box-shadow: 3px 3px 7px rgba(178, 178, 203, 0.2);
  border-radius: 3px;
  z-index: 77;

  @media (max-width: 768px) {
    left: 0;
    top: 102%;
    width: 100%;
    max-width: 100%;
  }

  .notFound {
    padding: 2rem 0;
    font-size: 0.75rem;
    font-weight: 300;
    display: block;
  }

  .noHeadline {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
    color: #0e1324;
    margin-top: 0.5rem;
    cursor: pointer;

    span {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f3f8;
      margin-right: 0.5rem;

      .icon {
        font-size: 0.8rem;
        color: #8e93a6;
      }
    }

    &:hover {
      font-weight: 500;

      span {
        .icon {
          color: #0e1324;
        }
      }
    }
  }

  .heading,
  .subTitle {
    width: 100%;
    border-bottom: 1px solid #eeeef6;
    padding-bottom: 1.25rem;
    display: flex;
    align-items: center;

    .icon {
      font-size: 1rem;
      color: #31374f;
    }

    .input2 {
      width: 100%;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875rem;
      color: #31374f;
      padding: 0 0.5rem;
      font-weight: 300;

      &::placeholder {
        color: #858aa0;
      }
    }
  }

  .subTitle {
    border: none;
    padding: 0.5rem 0;

    .icon.crown {
      font-size: 0.875rem;
      color: #eca842;
    }

    .text {
      font-size: 0.75rem;
      font-weight: 300;
      margin-left: 0.5rem;
      color: #676e92;
    }
  }

  .lists {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: auto;
  }
`
